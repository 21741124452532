import { setHours, setMinutes, setSeconds } from 'date-fns'

const parseTime = (time) => {
  const [hour, minute] = time.split(':')
  return setHours(setMinutes(setSeconds(new Date(), 0), minute), hour)
}

export const companyId = process.env.GATSBY_COMPANY_ID ?? 'root'

export const BASE_API_URL = process.env.GATSBY_BASE_API_URL ?? 'http://localhost:4004'

export const RECAPTCHA_SITE_KEY = process.env.GATSBY_RECAPTCHA_SITE_KEY ?? ''
export const RECAPTCHA_VALIDATE_URL = process.env.GATSBY_RECAPTCHA_VALIDATE_URL ?? `${BASE_API_URL}/recaptcha/validate`

export const BOOKING_OTP_VERIFY_URL = process.env.GATSBY_BOOKING_OTP_VERIFY_URL ?? `${BASE_API_URL}/otp/verify`

export const BOOKING_PAYMENT_URL = process.env.GATSBY_BOOKING_PAYMENT_URL ?? `${BASE_API_URL}/pay-booking?bookingId=`

export const SLOT_PUBLIC_LABEL = process.env.GATSBY_SLOT_PUBLIC_LABEL ?? 'Slot'
export const FEE_PUBLIC_LABEL = process.env.GATSBY_FEE_PUBLIC_LABEL ?? 'Fee'

export const PHONE_COUNTRIES = (process.env.GATSBY_PHONE_COUNTRIES ?? '').split(',')

export const CURRENCY = process.env.GATSBY_CURRENCY ?? 'MMK'

export const OTP_EXPIRED_SECONDS = process.env.GATSBY_OTP_EXPIRED_SECONDS ?? 60

export const FIELD_FILE = process.env.GATSBY_FIELD_FILE ?? 'fields.json'

export const OPEN_TIME = process.env.GATSBY_OPEN_TIME
  ? parseTime(process.env.GATSBY_OPEN_TIME)
  : setHours(setMinutes(setSeconds(new Date(), 0), 0), 7)
export const CLOSE_TIME = process.env.GATSBY_CLOSE_TIME
  ? parseTime(process.env.GATSBY_CLOSE_TIME)
  : setHours(setMinutes(setSeconds(new Date(), 0), 0), 19)
export const CALENDAR_TIME_STEP = parseInt(process.env.GATSBY_CALENDAR_TIME_STEP) ?? 15

export const BOOKING_EDITABLE = process.env.GATSBY_BOOKING_EDITABLE === 'true' ?? true

export const ANDROID_DOWNLOAD_CONFIRMATION = process.env.GATSBY_ANDROID_DOWNLOAD_CONFIRMATION
export const CONFIRMATION_AUTO_DOWNLOAD = process.env.GATSBY_CONFIRMATION_AUTO_DOWNLOAD === 'true'

export const paymentGateways = (process.env.GATSBY_PAYMENT_GATEWAYS ?? 'kbzpay,ayapay,cbpay,stripe,paypal').split(',')
